
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Invoice</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item">CBS</li>
              <li class="breadcrumb-item active">Invoice</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="Product">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'ProductList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-6 required">
                  <label class="control-label">Type</label>
                  <select class="form-control" style="width: 100%;" v-bind:class="{'has-error' : errors.has('type')}" v-validate="'required'" name="type" v-model="value.type" id="type">
                    <option value="Subscription">Subscription</option>
                    <option value="Recurring">Recurring</option>
                    <option value="Fixed">Fixed</option>
                    <option value="External-Charge">External-Charge</option>
                    <option value="Refund">Refund</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('type')">
                    {{ errors.first("type") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Status</label>
                  <select class="form-control" style="width: 100%;" v-bind:class="{'has-error' : errors.has('status')}" v-validate="'required'" name="status" v-model="value.status" id="status">
                    <option value="Created">Created</option>
                    <option value="Pending">Pending</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Paid">Paid</option>
                    <option value="Failed">Failed</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('status')">
                    {{ errors.first("status") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Order-Id</label>
                  <input type="text" id="order_id" name="order_id" v-bind:class="{'has-error' : errors.has('order_id')}" v-validate="'required'" v-model="value.order_id" class="form-control order_id" placeholder="Order ID">
                  <div class="help text-danger" v-show="errors.has('order_id')">
                    {{ errors.first("order_id") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Account-Id</label>
                  <input type="text" id="account_id" name="account_id" v-bind:class="{'has-error' : errors.has('account_id')}" v-validate="'required'" v-model="value.account_id" class="form-control account_id" placeholder="Account ID">
                  <div class="help text-danger" v-show="errors.has('account_id')">
                    {{ errors.first("account_id") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Currency</label>
                  <input type="text" id="currency" name="currency" v-bind:class="{'has-error' : errors.has('currency')}" v-validate="'required'" v-model="value.currency" class="form-control currency" placeholder="Currency">
                  <div class="help text-danger" v-show="errors.has('currency')">
                    {{ errors.first("currency") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Invoice At</label>
                  <date-picker type="datetime" class="col-12" input-class="form-control" :input-attr="{name:'invoice_at'}" v-model="value.invoice_at" lang="en" format="YYYY-MM-DD HH:mm:ss" id="invoice_at" value-type="YYYY-MM-DD HH:mm:ss"></date-picker>
                  <div class="help text-danger" v-show="errors.has('invoice_at')">
                    {{ errors.first("invoice_at") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Payment Attempt At</label>
                  <date-picker type="datetime" class="col-12" input-class="form-control" :input-attr="{name:'payment_attempt_at'}" v-model="value.payment_attempt_at" lang="en" format="YYYY-MM-DD HH:mm:ss" id="payment_attempt_at" value-type="YYYY-MM-DD HH:mm:ss"></date-picker>
                  <div class="help text-danger" v-show="errors.has('payment_attempt_at')">
                    {{ errors.first("payment_attempt_at") }}
                  </div>
                </div>
                <div class="form-group col-6">
                  <label class="control-label">Remarks</label>
                  <input type="text" id="remarks" name="remarks" v-model="value.remarks" class="form-control remarks" placeholder="remarks">
                  <div class="help text-danger" v-show="errors.has('remarks')">
                    {{ errors.first("remarks") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Gateway-Id</label>
                  <select id="gateway_id" v-model="value.gateway_id" v-bind:class="{'has-error' : errors.has('gateway_id')}" v-validate="'required'" class="form-control">
                    <option v-for="gateway in allGateways" :value="gateway.id" :key='gateway.id'>{{ gateway.name }}</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('gateway_id')">
                    {{ errors.first("gateway_id") }}
                  </div>
                </div>
                <div class="form-group col-6">
                  <label class="control-label">Linked Invoice-Id</label>
                  <input type="text" id="linked_invoice_id" name="linked_invoice_id" v-model="value.linked_invoice_id" class="form-control linked_invoice_id" placeholder="linked_invoice_id">
                  <div class="help text-danger" v-show="errors.has('linked_invoice_id')">
                    {{ errors.first("linked_invoice_id") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">App-Id</label>
                  <select id="app_id" v-model="value.app_id" v-bind:class="{'has-error' : errors.has('app_id')}" v-validate="'required'" class="form-control">
                    <option v-for="application in allApplications" :value="application.app_id" :key='application.app_id'>{{ application.name }}</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('app_id')">
                    {{ errors.first("app_id") }}
                  </div>
                </div>
                <div class="offset-0 col-12">
                  <h4 class="site-heading my-3"><span>Gateway Parameters</span></h4>
                  <div class="form-row align-items-end" v-for="(key_val, i) in value.gateway_params_converted" :key="i">
                    <div class=" col-md-10">
                      <div class="row">
                        <div class="col">
                          <div class="form-group col-md-12">
                            <label class="control-label">Key</label>
                            <input type="text" name="key_val.key" v-model="key_val.key" class="form-control key_val.key" placeholder="Key">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group col-md-12">
                            <label class="control-label">Value</label>
                            <input type="text" name="key_val.value" v-model="key_val.value" class="form-control key_val.value" placeholder="Value">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <button type="button" class="btn btn-danger btn-sm float-right" @click="delete_key_value(i)"><i class="fa fa-trash"></i> Remove</button>
                    </div>
                  </div>
                  <div class="form-group col-12 text-right">
                    <button type="button" @click="add_key_value()" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                </div>

                <div class="has-many col-12">
                  <h4 class="site-heading my-3"><span>Invoice Items</span></h4>
                  <div class="form-row align-items-end" v-for="(invoice_item, index) in value.invoice_item" :key="index">
                    <div class="form-group col-4 required">
                      <label class="control-label">Subscription-Id</label>
                      <input type="text" id="invoice_item.subscription_id" name="invoice_item.subscription_id" v-bind:class="{'has-error' : errors.has('invoice_item.name')}" v-validate="'required'" v-model="invoice_item.name" class="form-control invoice_item.name" placeholder="name">
                      <div class="help text-danger" v-show="errors.has('invoice_item.subscription_id')">
                        {{ errors.first("invoice_item.subscription_id") }}
                      </div>
                    </div>
                    <div class="form-group col-4 required">
                      <label class="control-label">Product-Id</label>
                      <select id="product_id" v-model="invoice_item.product_id" v-bind:class="{'has-error' : errors.has('product_id')}" v-validate="'required'" class="form-control">
                        <option v-for="product in allProducts" :value="product.id" :key='product.id'>{{ product.name }}</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('product_id')">
                        {{ errors.first("product_id") }}
                      </div>
                    </div>
                    <div class="form-group col-4 required">
                      <label class="control-label">Plan Id</label>
                      <select id="product_id" v-model="invoice_item.product_id" v-bind:class="{'has-error' : errors.has('product_id')}" v-validate="'required'" class="form-control">
                        <option v-for="product in allProducts" :value="product.id" :key='product.id'>{{ product.name }}</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('product_id')">
                        {{ errors.first("product_id") }}
                      </div>
                    </div>
                    <div class="form-group col-12 border-bottom pb-3">
                      <button type="button" class="btn btn-danger float-right" @click="delete_invoice_item(index)"><i class="fa fa-trash"></i> Remove</button>
                    </div>
                  </div>
                  <div class="col-12 text-left">
                    <button type="button" @click="add_invoice_item" class="btn btn-success"><i class="fa fa-plus"></i> New </button>
                  </div>
                </div>
                <div class="has-many col-12">
                  <h4 class="site-heading my-3"><span>Invoice Payments</span></h4>
                  <div class="form-row align-items-end" v-for="(invoice_payment, index) in value.invoice_payment" :key="index">
                    <div class="form-group col-6 required">
                      <label class="control-label">Type</label>
                      <select class="form-control" style="width: 100%;" id="invoice_payment.type" name="invoice_payment.type" v-bind:class="{'has-error' : errors.has('invoice_payment.type')}" v-validate="'required'" v-model="invoice_payment.type">
                        <option value="Self">Self</option>
                        <option value="Component">Component</option>
                        <option value="Complement">Complement</option>
                        <option value="Incompatible">Incompatible</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('invoice_payment.type')">
                        {{ errors.first("invoice_payment.type") }}
                      </div>
                    </div>
                    <div class="form-group col-6 required">
                      <label class="control-label">Associated Product</label>
                      <select id="invoice_payment.associated" name="invoice_payment.associated" v-bind:class="{'has-error' : errors.has('invoice_payment.associated')}" v-validate="'required'" v-model="invoice_payment.associated" class="form-control">
                        <option v-for="product in allProducts" :value="product.id" :key='product.id'>{{ product.name }}</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('invoice_payment.associated')">
                        {{ errors.first("invoice_payment.associated") }}
                      </div>
                    </div>
                    <div class="form-group col-6 required">
                      <label class="control-label">Quantitye</label>
                      <input type="number" id="invoice_payment.quantity" name="invoice_payment.quantity" v-bind:class="{'has-error' : errors.has('invoice_payment.quantity')}" v-validate="'required'" v-model="invoice_payment.quantity" class="form-control invoice_payment.quantity" placeholder="Quantitye">
                      <div class="help text-danger" v-show="errors.has('invoice_payment.quantity')">
                        {{ errors.first("invoice_payment.quantity") }}
                      </div>
                    </div>
                    <div class="form-group col-6 required">
                      <label class="control-label">Status</label>
                      <select class="form-control" style="width: 100%;" id="invoice_payment.status" name="invoice_payment.status" v-bind:class="{'has-error' : errors.has('invoice_payment.status')}" v-validate="'required'" v-model="invoice_payment.status">
                        <option :value="1">Active</option>
                        <option :value="0">Inactive</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('invoice_payment.status')">
                        {{ errors.first("invoice_payment.status") }}
                      </div>
                    </div>

                    <div class="form-group col-md-12 border-bottom pb-3">
                      <button type="button" class="btn btn-danger float-right" @click="delete_invoice_payment(index)"><i class="fa fa-trash"></i> Remove</button>
                    </div>
                  </div>
                  <div class="col-12 text-left">
                    <button type="button" @click="add_invoice_payment" class="btn btn-success"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { CBSProductApi, CBSCommonApi } from '@/services/api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'InvoiceForm',
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {
      gateway_params: {},
      gateway_params_converted: [],
      invoice_item: [],
      invoice_payment: [],
    },
    allApplications: [],
    allProductCategory: [],
    allProducts: [],
    allGateways: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSProductApi.updateProduct(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'ProductList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSProductApi.storeProduct(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'ProductList' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    add_key_value: function () {
      this.value.gateway_params_converted.push({});
    },
    delete_key_value: function (index) {
      this.value.gateway_params_converted.splice(index, 1)
    },
    add_invoice_item: function () {
      this.value.invoice_item.push({});
    },
    add_invoice_payment: function () {
      this.value.invoice_payment.push({});
    },

    delete_invoice_item: function (index) {
      this.value.invoice_item.splice(index, 1)
    },
    delete_invoice_payment: function (index) {
      this.value.invoice_payment.splice(index, 1)
    },

    getDetail: function (id) {
      CBSProductApi.showProduct(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getApplications: function () {
      CBSCommonApi.getAllApplications().then((response) => {
        this.allApplications = response
      })
    },
    getPaymentGateways: function () {
      CBSCommonApi.getAllPaymentGateway().then((response) => {
        this.allGateways = response
      })
    },
    getProductCategory: function () {
      CBSCommonApi.getAllProductCategory().then((response) => {
        this.allProductCategory = response
      })
    },
    getProducts: function () {
      CBSCommonApi.getAllProducts().then((response) => {
        this.allProducts = response
      })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      }
      await this.getApplications()
      await this.getPaymentGateways()
      await this.getProductCategory()
      await this.getProducts()
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
